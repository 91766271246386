/**
 * Collects tracking data for the main impression.
 * @module baseImpressionCollector
 */
function BaseImpressionCollector(injectedLogger, breakpointDetector) {
    const logger = injectedLogger || { debug() {} };
    const parseDataToChefkochModel = (data, chefkochModel) => {
        logger.debug(`Parse document.body attribute data-tracking ${data}`);
        const json = JSON.parse(data);
        let key;
        for (key in json) {
            /* istanbul ignore else */
            if (Object.prototype.hasOwnProperty.call(json, key)) {
                chefkochModel[key] = json[key];
            }
        }
    };

    /**
     * Get base impression data from <body> and update chefkochModel.
     * @param {Object} document
     * @param {Object} chefkochModel
     */
    this.collect = (document, chefkochModel) => {
        chefkochModel.type = 'impression';

        const body = document.querySelector('body');
        if (body) {
            let data = body.getAttribute('data-tracking');
            if (data) {
                parseDataToChefkochModel(data, chefkochModel);
            } else {
                data = body.getAttribute(`data-tracking-${breakpointDetector.getBreakpoint(document)}`);
                if (data) {
                    parseDataToChefkochModel(data, chefkochModel);
                }
            }
        } else {
            logger.debug('DOM error: no body element not found');
        }
    };
}

export default BaseImpressionCollector;
