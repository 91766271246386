function TrackingService(deferredExecution, impressionMapper, dataLayerService, logger, teaserMapper, eventMapper) {
    const impressions = [];
    const teasers = [];
    const events = [];

    let documentReady = false;

    const sendTracking = () => {
        logger.debug('[gtm] Function sendTracking triggered');
        if (!impressions.length && !teasers.length) {
            return;
        }

        while (impressions.length) {
            impressionMapper.map(impressions.shift(), dataLayerService);
        }
        if (teasers.length) {
            const currentTeasers = [];
            while (teasers.length) {
                currentTeasers.push(teasers.shift());
            }
            teaserMapper.map(currentTeasers, dataLayerService);
        }
    };

    const scheduleNewSendEvent = () => {
        // Do not schedule new send events if document is not yet ready
        if (!documentReady) {
            return;
        }

        deferredExecution.execute(sendTracking);
    };

    this.trackImpression = (impression) => {
        // Schedule a new send event, lowering costs
        impressions.push(impression);
        scheduleNewSendEvent();
    };

    this.trackTeaser = (teaser) => {
        // Schedule a new send event, lowering costs
        teasers.push(teaser);
        scheduleNewSendEvent();
    };

    this.trackEvent = (event) => {
        if (documentReady) {
            // Do not schedule events, send a pixel immediatelly
            eventMapper.map(event, dataLayerService);
        } else {
            // Schedule events
            events.push(event);
        }
    };

    /* eslint-disable no-unused-vars */
    this.trackPageView = (pageView) => {
        const chefkochModel = pageView === undefined ? {} : pageView;
        impressionMapper.map(chefkochModel, dataLayerService);
    };
    /* eslint-enable no-unused-vars */

    this.initialTrackingIsFinished = () => {
        documentReady = true;

        if (!impressions.length) {
            throw new Error('There must be at least one impression before TrackingService::onDocumentReady is called. Perhaps the event listener of DocumentParser was not registered early enough?');
        }

        // Send an initial pageview pixel
        const firstImpression = impressions.shift();

        impressionMapper.map(firstImpression, dataLayerService);
        if (teasers.length) {
            const currentTeasers = [];
            while (teasers.length) {
                currentTeasers.push(teasers.shift());
            }
            teaserMapper.map(currentTeasers, dataLayerService);
        }

        // If there is more than one impression, schedule another send event
        if (impressions.length) {
            scheduleNewSendEvent();
        }

        // Send scheduled events
        if (events.length) {
            while (events.length) {
                this.trackEvent(events.shift());
            }
        }
    };

    this.onWindowUnload = () => {
        logger.debug('[gtm] Unload triggered');
        sendTracking();
    };
}

export default TrackingService;
