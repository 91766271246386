/**
 * Collects user data for tracking.
 * @module userDataCollector
 */
function UserDataCollector(injectedLogger) {
    const logger = injectedLogger || { debug() { } };
    const userMeEndpoint = 'https://www.chefkoch.de/benutzer/me';

    /**
     * Collect user data from chefkoch benutzer/me and return as a promise.
     */
    this.collect = () =>
        new Promise((resolve, reject) => {
            const result = {
                authenticated: false,
                hashedUserId: null,
            };

            this.sendRequest(
                (xhr) => {
                    const rawUserInfos = xhr.responseText;
                    try {
                        const response = JSON.parse(rawUserInfos);
                        if (response.id) {
                            result.authenticated = true;
                            result.hashedUserId = response.trackingId;
                            result.termsOfUse = response.termsOfUse;
                            result.activePurchasedProduct = response.activePurchasedProduct;
                            result.entitlements = [];
                            if (response.entitlements !== null && response.entitlements !== undefined && Array.isArray(response.entitlements)) {
                                result.entitlements = response.entitlements;
                            }
                        }
                    } catch (error) {
                        reject(error);
                        return;
                    }
                    logger.debug(`Collected userdata: ${JSON.stringify(result)}.`);
                    resolve({ user: result });
                },
                (error) => {
                    reject(new Error(`Error when requesting user information: ${error}`));
                },
            );
        });

    this.sendRequest = (onDone, onError) => {
        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                onDone(xhr);
            }
        };

        xhr.open('GET', userMeEndpoint);
        xhr.withCredentials = true;
        xhr.timeout = 2000; // 2s
        xhr.addEventListener('error', onError);
        xhr.addEventListener('timeout', onError);

        xhr.send();
    };
}

export default UserDataCollector;
