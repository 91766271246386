function CompositeMapper(injectedLogger) {
    const logger = injectedLogger || { debug() {} };
    const mappers = [];
    let impressionData = [];


    this.addMapper = (mapper) => {
        logger.debug('[gtm] Add new mapper');
        mappers.push(mapper);
    };

    this.map = (chefkochModel, dataLayerService) => {
        logger.debug('[gtm] Start mapping');
        mappers.forEach((mapper) => {
            impressionData.push(mapper.map(chefkochModel));
        });
        dataLayerService.pageview(Object.assign({}, ...impressionData));
        impressionData = [];
    };
}

export default CompositeMapper;

