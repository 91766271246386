const loggerConfigurator = (logger, featureFlag, debugHandler) => {
    if (featureFlag.isEnabled('debug')) {
        logger.setLevel(logger.DEBUG);
        logger.setHandler(debugHandler);
    } else {
        logger.setLevel(logger.OFF);
    }

    return logger;
};

export default loggerConfigurator;
