function TeaserTrackingContext() {
    const trackingStrategies = [];
    let currentStrategy;
    const defaultStrategyName = 'trackVisibleElements';

    this.addTrackingStrategy = (trackingStrategy) => {
        trackingStrategies[trackingStrategy.getName()] = trackingStrategy;
    };

    this.getTrackingStrategies = () => trackingStrategies;

    this.selectStrategyByName = (name) => {
        let strategyName = name;
        if (!strategyName || !(strategyName in trackingStrategies)) {
            strategyName = defaultStrategyName;
        }
        currentStrategy = trackingStrategies[strategyName];
    };

    this.getCurrentStrategy = () => currentStrategy;


    this.track = (data, element, trackingService) => {
        if (currentStrategy) {
            currentStrategy.track(data, element, trackingService);
        }
    };
}
export default TeaserTrackingContext;
