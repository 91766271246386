import container from 'chefkoch-containerjs';
import CampaignTeaserClickTrackingDecision from './campaignTeaserClickTrackingDecision';
import PositionExceptionClickTrackingDecision from './positionExceptionClickTrackingDecision';
import CompositeTeaserClickTrackingDecision from './compositeTeaserClickTrackingDecision';

container.service('campaignTeaserClickTrackingDecision', CampaignTeaserClickTrackingDecision, 'campaignContentResolver', 'logger');
container.service('positionExceptionClickTrackingDecision', PositionExceptionClickTrackingDecision, 'logger');
container.factory('compositeTeaserClickTrackingDecision', (c) => {
    const compositeCTdecision = new CompositeTeaserClickTrackingDecision(c.get('logger'));

    compositeCTdecision.addDecisionRule(c.get('campaignTeaserClickTrackingDecision'));
    compositeCTdecision.addDecisionRule(c.get('positionExceptionClickTrackingDecision'));

    return compositeCTdecision;
});
