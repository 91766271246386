function WindowUnloadNotifier(window) {
    const listeners = [];
    let initialized = false;

    const notifyListeners = () => listeners.forEach(listener => listener.onWindowUnload());

    this.addListener = (listener) => {
        if (initialized) {
            throw new Error('Listeners can only be registrered on the WindoUnloadNotifier, when it has not already been initialized.');
        }
        listeners.push(listener);
    };

    this.init = () => {
        initialized = true;
        window.addEventListener('beforeunload', notifyListeners);
    };
}

export default WindowUnloadNotifier;
