/**
 * Collects breakpoint data for tracking.
 * @module responsiveViewCollector
 */
function ResponsiveViewCollector(breakpointDetector) {
    /**
     * Get viewport width on load, determine chefkoch breakpoint
     * and update chefkochModel.
     * @param {Object} document
     * @param {Object} chefkochModel
     */
    this.collect = (document, chefkochModel) => {
        const responsiveView = {};

        responsiveView.breakpoint = breakpointDetector.getBreakpoint(document);
        chefkochModel.responsiveView = responsiveView;
    };
}

export default ResponsiveViewCollector;
