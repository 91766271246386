function IntersectionObserverWrapper(IObserver) {
    this.queue = [];
    this.observer = {};
    this.IObserver = IObserver || {};

    this.createObserver = () => {
        this.observer = new this.IObserver(this.handleIntersect);
    };

    this.handleIntersect = (entries) => {
        this.queue.forEach((item) => {
            entries.forEach((entry) => {
                if (entry.target === item.element) {
                    item.callback(entry);
                }
            });
        });
    };

    this.addElementToObserver = (element, fn) => {
        this.queue.push({
            callback: fn,
            element,
        });

        this.observer.observe(element);
    };

    this.removeElementFromObserver = (element) => {
        this.observer.unobserve(element);

        this.queue = this.queue.filter(item => item.element !== element);
    };
}

export default IntersectionObserverWrapper;
