import container from 'chefkoch-containerjs';
import Registry from './registry';
import Probability from './probability';
import Cookie from './cookie';
import AnyFeatureFlag from './any';

container.value('debugCookieName', 'FEATURE_FLAG_TRACKING_DEBUG');
container.service('debugFeatureFlag', Cookie, 'document', 'debugCookieName');

container.value('remoteLoggingCookieName', 'FEATURE_FLAG_TRACKING_FORCE_REMOTE_LOGGING');
container.service('remoteLoggingCookieFeatureFlag', Cookie, 'document', 'remoteLoggingCookieName');
container.factory('remoteLoggingProbabilityFeatureFlag', c => new Probability(c.get('window').Math.random, 0.01));
container.service('remoteLoggingFeatureFlag', AnyFeatureFlag, 'remoteLoggingProbabilityFeatureFlag', 'remoteLoggingCookieFeatureFlag');

container.service('validateFeatureFlag', AnyFeatureFlag, 'debugFeatureFlag', 'remoteLoggingFeatureFlag');

container.factory('featureFlag', (c) => {
    const registry = new Registry();

    registry.addFeatureFlag('debug', c.get('debugFeatureFlag'));
    registry.addFeatureFlag('remoteLogging', c.get('remoteLoggingFeatureFlag'));
    registry.addFeatureFlag('validate', c.get('validateFeatureFlag'));

    return registry;
});
