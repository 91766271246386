import container from 'chefkoch-containerjs';
import TeaserTrackingContext from './teaserTrackingContext';
import TeaserTrackAlwaysStrategy from './teaserTrackAlwaysStrategy';
import TeaserTrackNoImpression from './teaserTrackNoImpressionStrategy';
import TeaserTrackVisibleElementsStrategy from './teaserTrackVisibleElementsStrategy';
import TeaserTrackOnceOnIntersectionStrategy from './teaserTrackOnceOnIntersectionStrategy';
import IntersectionObserverWrapper from '../intersectionObserverWrapper';

container.factory('teaserTrackingContext', () => {
    const teaserTrackingContext = new TeaserTrackingContext();

    const observerWrapper = new IntersectionObserverWrapper(IntersectionObserver);
    observerWrapper.createObserver();

    teaserTrackingContext.addTrackingStrategy(new TeaserTrackAlwaysStrategy());
    teaserTrackingContext.addTrackingStrategy(new TeaserTrackVisibleElementsStrategy());
    teaserTrackingContext.addTrackingStrategy(new TeaserTrackNoImpression());
    teaserTrackingContext.addTrackingStrategy(new TeaserTrackOnceOnIntersectionStrategy(observerWrapper));
    return teaserTrackingContext;
});
