
function FeatureFlagRegistry() {
    const registry = {};

    this.addFeatureFlag = (name, featureFlag) => {
        registry[name] = featureFlag;
    };
    this.isEnabled = (name) => {
        if (!registry[name]) {
            return false;
        }

        return registry[name].isEnabled();
    };
}

export default FeatureFlagRegistry;
