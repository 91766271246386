
function TeaserClickEventMapper(campaignContentResolver, compositeTeaserClickTrackingDecision, injectedLogger) {
    const logger = injectedLogger || { debug() { } };

    /**
     * map and send video event
     * @param {Object} event
     */
    this.map = (event) => {
        const item = {};
        if (event.name !== 'click' ||
            !event.target ||
            event.target.type !== 'teaser') {
            return {};
        }

        const content = event.target.reference;
        if (!content) {
            return {};
        }

        const campaign = campaignContentResolver.resolve(content);

        // This is a list of reasons, why we do track teaser clicks.
        if (compositeTeaserClickTrackingDecision.shouldTrackTeaserClick(event) === false) {
            return {};
        }

        const contentId = `${content.type}-${content.id}`;

        item.eventName = 'select_item';
        item.item_list_name = event.target.position;
        item.item_id = contentId;
        item.item_name = content.name || content.title || '';
        item.index = event.target.offset;

        if (event.target.reason) {
            item.dimension17 = event.target.reason;
        }

        if (event.target.personalized) {
            item.personalized = 'personalized';
        }

        if (campaign !== null) {
            const campaignId = `${campaign.type}-${campaign.id}`;
            item.item_campaign_id = campaignId;
        }

        if (content.imageId) {
            item.dimension65 = content.imageId;
        }

        if (content.rating !== null && content.rating !== undefined) {
            item.dimension69 = content.rating;
        }

        if (content.view) {
            item.dimension82 = content.view;
        }

        // This should be refactored to reuse the decision from above (again strategy?)
        if (event.target.position.match(/^cookbook/)) {
            item.dimension3 = contentId;
        }

        logger.debug(`[gtm] adding ${JSON.stringify(item)} to dataLayer`);

        return item;
    };
}

export default TeaserClickEventMapper;
