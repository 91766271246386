// map tou status from /benutzer/me to required tracking status
const touStatusMap = {
    agreementDesired: 'desired',
    agreementRequired: 'required',
    ok: 'ok',
};

function UserMapper(injectedLogger) {
    const logger = injectedLogger || { debug() {} };

    this.map = (chefkochModel) => {
        if (chefkochModel.user) {
            const userData = {};
            // @see: https://jira.chefkoch.de/browse/DS-23

            // user state
            const userState = chefkochModel.user.authenticated === true ? 'logged_in' : 'logged_out';
            logger.debug(`[gtm] Mapping user state ${userState} into dlv.login_status`);
            userData.login_status = userState;

            // user id
            const hashedUserId = chefkochModel.user.hashedUserId;
            logger.debug(`[gtm] Mapping hashed userId ${hashedUserId} into dlv.user_id`);
            userData.user_id = hashedUserId;

            // user entitlements
            if (chefkochModel.user.authenticated === true && Array.isArray(chefkochModel.user.entitlements)) {
                userData.user_entitlements = chefkochModel.user.entitlements.join();
            }

            // user activePurchasedProduct
            if (chefkochModel.user.authenticated === true && chefkochModel.user.activePurchasedProduct) {
                userData.active_purchased_product = chefkochModel.user.activePurchasedProduct;
            }

            // user terms of use status
            if (chefkochModel.user.authenticated === true && chefkochModel.user.termsOfUse && chefkochModel.user.termsOfUse.status) {
                const termsOfUseStatus = touStatusMap[chefkochModel.user.termsOfUse.status] || '';
                logger.debug(`[gtm] Mapping user's terms fo use status ${termsOfUseStatus} into dlv.dimension96`);
                userData.dimension96 = termsOfUseStatus;
            }

            return userData;
        }
        return {};
    };
}

export default UserMapper;
