function PayedContentTypeMapper(injectedLogger) {
    const logger = injectedLogger || { debug() {} };

    this.map = (chefkochModel) => {
        if (chefkochModel.payedContentType || chefkochModel.payedContentType === '') {
            const payedContent = {};
            logger.debug(`[gtm] Mapping ${chefkochModel.payedContentType} on Google Analytics dimension115`);
            payedContent.dimension115 = chefkochModel.payedContentType;
            return payedContent;
        }
        return {};
    };
}

export default PayedContentTypeMapper;
