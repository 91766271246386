function CompositeCollector(injectedLogger) {
    const logger = injectedLogger || { debug() {} };
    const collectors = [];

    /**
     * Register a collector.
     * @param {Object} collector
     */
    this.addCollector = (collector) => {
        logger.debug('Add new collector');
        collectors.push(collector);
    };

    /**
     * Update chefkochModel with collect interface of collectors.
     * @param {Object} document
     * @param {Object} chefkochModel
     */
    this.collect = (document, chefkochModel) => {
        logger.debug('Start collecting');
        collectors.forEach(collector => collector.collect(document, chefkochModel));
    };
}

export default CompositeCollector;
