function CompositeTeaserClickTrackingDecision(injectedLogger) {
    const logger = injectedLogger || { debug() {} };
    const rules = [];

    this.addDecisionRule = (rule) => {
        logger.debug('Add new rule');
        rules.push(rule);
    };

    this.shouldTrackTeaserClick = (event) => {
        logger.debug('Start checking for click tracking exceptions');
        /*
            As soon as one rule defines that the teaser click should be tracked, then return true
            @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
         */
        return rules.some(rule => rule.shouldTrackTeaserClick(event));
    };
}

export default CompositeTeaserClickTrackingDecision;
