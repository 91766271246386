function CompositeTrackingService(logger, validator, webVitals) {
    const trackingServices = [];
    let isDisabled = false;

    const validateData = (data, type) => {
        if (validator) {
            validator.validate(type, data, (errorsText) => {
                logger.error(errorsText);
            });
        }
    };

    this.addTrackingService = trackingService => trackingServices.push(trackingService);

    this.disable = (shouldBeDisabled) => {
        isDisabled = shouldBeDisabled;
    };

    this.trackImpression = (impression) => {
        if (!isDisabled) {
            validateData(impression, 'impression');
            logger.debug('Track new impression');
            trackingServices.forEach(trackingService => trackingService.trackImpression(impression));
        }
    };

    this.trackTeaser = (teaser) => {
        if (!isDisabled) {
            validateData(teaser, 'teaser');
            logger.debug(`Track new teaser ${JSON.stringify(teaser)}`);
            trackingServices.forEach(trackingService => trackingService.trackTeaser(teaser));
        }
    };

    this.trackEvent = (event) => {
        if (!isDisabled) {
            validateData(event, 'event');
            logger.debug(`Track new event ${JSON.stringify(event)}`);
            trackingServices.forEach(trackingService => trackingService.trackEvent(event));
        }
    };

    this.trackPageView = (pageView) => {
        webVitals.trackWebVitals(this);
        if (!isDisabled) {
            logger.debug('Track new pageView');
            trackingServices.forEach(trackingService => trackingService.trackPageView(pageView));
        }
    };

    this.initialTrackingIsFinished = () => {
        if (!isDisabled) {
            trackingServices.forEach(trackingService => trackingService.initialTrackingIsFinished());
        }
    };
}

export default CompositeTrackingService;
