import container from 'chefkoch-containerjs';
import './collectors/services';
import './logger/services';
import './featureFlag/services';
import './googleAnalytics/services';
import './gtm/services';
import './validation/services';
import './teaserTrackingStrategy/services';
import CompositeTrackingService from './compositeTrackingService';
import DocumentReadyNotifier from './documentReadyNotifier';
import WindowUnloadNotifier from './windowUnloadNotifier';
import WindowUnloadAdapter from './windowUnloadAdapter';
import DocumentParser from './documentParser';
import LazyScriptLoader from './lazyScriptLoader';
import BreakpointDetector from './breakpointDetector';
import WebVitals from './webVitals';

container.service('documentParser', DocumentParser, 'impressionCollector', 'trackingService', 'teaserTrackingContext', 'webVitals', 'userDataCollector');

container.factory('documentReadyNotifier', (c) => {
    const documentReadyNotifier = new DocumentReadyNotifier(c.get('document'));
    documentReadyNotifier.addListener(c.get('documentParser'));
    documentReadyNotifier.addListener(c.get('windowUnloadAdapter'));
    return documentReadyNotifier;
});

container.factory('windowUnloadNotifier', (c) => {
    const windowUnloadNotifier = new WindowUnloadNotifier(c.get('window'));
    windowUnloadNotifier.addListener(c.get('gtmTrackingService'));
    return windowUnloadNotifier;
});

container.service('windowUnloadAdapter', WindowUnloadAdapter, 'windowUnloadNotifier');

container.factory('trackingService', (c) => {
    const trackingService = new CompositeTrackingService(c.get('logger'), c.get('validator'), c.get('webVitals'));
    trackingService.addTrackingService(c.get('gtmTrackingService'));
    return trackingService;
});

container.factory('lazyScriptLoader', c => new LazyScriptLoader(c.get('document'), c.get('window')));

container.factory('breakpointDetector', c => new BreakpointDetector(c.get('logger')));

container.factory('webVitals', () => WebVitals);
