function isDevHost(window) {
    return (window.location.host || '').startsWith('tracking.local.dev.chefkoch.de');
}

function makeAbsoluteUrl(src, window) {
    const url = new window.URL(src, isDevHost(window) ? `https://${window.location.host}` : 'https://www.chefkoch.de');
    return url.href;
}

function LazyScriptLoader(document, window) {
    this.load = (scriptSrc, callback) => {
        const script = document.createElement('script');
        script.src = makeAbsoluteUrl(scriptSrc, window);
        script.async = 1;
        script.addEventListener('load', callback);
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    };
}

export default LazyScriptLoader;
