function BreakpointDetector(injectedLogger) {
    const logger = injectedLogger || { debug() {} };
    const getCurrentWidth = (document) => {
        if (document.body && document.body.clientWidth) {
            return document.body.clientWidth;
        }
        return 599;
    };
    // http://design.chefkoch.de/#group-breakpoints-component-viewports

    this.getBreakpoint = (document) => {
        const currentWidth = getCurrentWidth(document);
        let breakpoint = '';

        if (currentWidth < 600) {
            breakpoint = 'xs';
        } else if (currentWidth < 960) {
            breakpoint = 's';
        } else if (currentWidth < 1440) {
            breakpoint = 'm';
        } else if (currentWidth < 1920) {
            breakpoint = 'l';
        } else {
            breakpoint = 'xl';
        }

        logger.debug(`Breakpoint ${breakpoint} for width ${currentWidth} selected.`);
        return breakpoint;
    };
}

export default BreakpointDetector;
