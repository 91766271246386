function FeatureFlaggedValidator(featureFlagRegistry, featureFlag, decoratedValidator) {
    let validator = decoratedValidator;


    if (!featureFlagRegistry.isEnabled(featureFlag)) {
        validator = null;
    }

    this.validate = (schema, data, errorCallback) => {
        if (validator) {
            validator.validate(schema, data, errorCallback);
        }
    };
}

export default FeatureFlaggedValidator;
