import container from 'chefkoch-containerjs';
import 'element-closest';
import 'intersection-observer';
import './services/services';

container.constant('window', window);
container.constant('document', document);

window.ck.tracking.setTrackingService(container.get('trackingService'));

// Tell Google Tagmanager core.tracking exists
const dataLayer = window.dataLayer || [];
dataLayer.push({ 'ck.tracking.exists': true, event: 'ck-core-tracking-loaded' });

try {
    const documentReadyNotifier = container.get('documentReadyNotifier');
    documentReadyNotifier.init();
} catch (exception) {
    let message;
    if (exception instanceof Error) {
        message = exception.toString();
    } else {
        message = exception;
    }
    container.get('logger').error(message);
}
