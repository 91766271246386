function TeaserMapper(campaignContentResolver, injectedLogger) {
    const logger = injectedLogger || {
        debug() {
        },
    };

    this.map = (teasers, dataLayerService) => {
        const items = [];
        while (teasers.length > 0) {
            const teaser = teasers.shift();
            const teaserImpression = {
                item_name: teaser.reference.name || teaser.reference.title,
                item_list_name: teaser.position,
                item_id: `${teaser.reference.type}-${teaser.reference.id}`,
                item_index: teaser.offset,
            };

            const personalized = teaser.personalized || null;
            const campaign = campaignContentResolver.resolve(teaser.reference);

            if (campaign !== null) {
                const campaignId = `${campaign.type}-${campaign.id}`;
                teaserImpression.item_campaign_id = campaignId;
            }

            if (personalized !== null) {
                teaserImpression.category = 'personalized';
                teaserImpression.dimension30 = '1';
            }

            if (teaser.contentType) {
                teaserImpression.dimension16 = teaser.contentType;
            }

            if (teaser.reason) {
                teaserImpression.dimension17 = teaser.reason;
            }

            if (teaser.reference.imageId) {
                teaserImpression.dimension65 = teaser.reference.imageId;
            }

            if (teaser.reference.rating !== null && teaser.reference.rating !== undefined) {
                teaserImpression.dimension69 = teaser.reference.rating;
            }

            if (teaser.reference.view) {
                teaserImpression.dimension82 = teaser.reference.view;
            }

            logger.debug(`[gtm] Mapping teaser ${JSON.stringify(teaserImpression)} to data layer`);

            items.push(teaserImpression);
        }
        dataLayerService.trackEcommerceEvent('view_item_list', items);
    };
}

export default TeaserMapper;
