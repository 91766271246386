import container from 'chefkoch-containerjs';
import CompositeCollector from './compositeCollector';
import BaseImpressionCollector from './baseImpressionCollector';
import ResponsiveViewCollector from './responsiveViewCollector';
import UserDataCollector from './userDataCollector';

container.factory('impressionCollector', (c) => {
    const compositeCollector = new CompositeCollector(c.get('logger'));

    compositeCollector.addCollector(c.get('baseImpressionCollector'));
    compositeCollector.addCollector(c.get('responsiveViewCollector'));
    return compositeCollector;
});
container.service('baseImpressionCollector', BaseImpressionCollector, 'logger', 'breakpointDetector');
container.service('responsiveViewCollector', ResponsiveViewCollector, 'breakpointDetector');
container.service('userDataCollector', UserDataCollector, 'logger');
