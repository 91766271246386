function TeaserTrackOnceOnIntersectionStrategy(observerWrapper) {
    this.observerWrapper = observerWrapper || {};
    this.trackingService = {};
    this.dataQueue = [];

    this.getName = () => 'trackOnceOnIntersection';

    this.sendCall = (intersectionEntry) => {
        if (intersectionEntry.isIntersecting) {
            const data = this.dataQueue.reduce(
                (value, item) => (item.target === intersectionEntry.target ? item.data : value),
                {},
            );

            this.trackingService.trackTeaser(data);
            this.observerWrapper.removeElementFromObserver(intersectionEntry.target);
            this.dataQueue = this.dataQueue.filter(item => item.target !== intersectionEntry.target);
        }
    };

    this.addElementToDataQueue = (data, element) => {
        this.dataQueue.push({
            data,
            target: element,
        });
    };

    this.track = (data, element, trackingService) => {
        this.trackingService = trackingService;

        this.addElementToDataQueue(data, element);

        this.observerWrapper.addElementToObserver(element, this.sendCall);
    };
}

export default TeaserTrackOnceOnIntersectionStrategy;
