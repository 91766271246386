import container from 'chefkoch-containerjs';
import SchemaConfigurator from './schemaConfigurator';
import FeatureFlaggedValidator from './featureFlaggedValidator';
import LazyLoadedValidator from './lazyLoadedValidator';
import AjvValidatorLoader from './ajvValidatorLoader';
import AjvBasedValidator from './ajvBasedValidator';

container.service('validationSchemaConfigurator', SchemaConfigurator);

container.factory('ajvValidatorLoader', c =>
    new AjvValidatorLoader(
        c.get('lazyScriptLoader'),
        c.get('window'),
        c.get('validationSchemaConfigurator'),
        ajv => new AjvBasedValidator(ajv),
    ),
);
container.service('lazyLoadedValidator', LazyLoadedValidator, 'ajvValidatorLoader');

container.value('validatorFeatureFlag', 'validate');
container.service('validator', FeatureFlaggedValidator, 'featureFlag', 'validatorFeatureFlag', 'lazyLoadedValidator');
