// eslint-disable-next-line import/extensions
import { onCLS, onFID, onLCP, onINP } from 'web-vitals';

let pageViewCounter = 0;

const trackCLS = (trackingService, { value }) => {
    if (pageViewCounter < 2) {
        const event = {
            type: 'event',
            name: 'CLS',
            value,
        };
        trackingService.trackEvent(event);
    }
};

const trackFID = (trackingService, { value }) => {
    if (pageViewCounter < 2) {
        const event = {
            type: 'event',
            name: 'FID',
            value,
        };
        trackingService.trackEvent(event);
    }
};

const trackLCP = (trackingService, { value }) => {
    if (pageViewCounter < 2) {
        const event = {
            type: 'event',
            name: 'LCP',
            value,
        };
        trackingService.trackEvent(event);
    }
};

const trackINP = (trackingService, { value }) => {
    if (pageViewCounter < 2) {
        const event = {
            type: 'event',
            name: 'INP',
            value,
        };
        trackingService.trackEvent(event);
    }
};

const trackWebVitals = (trackingService) => {
    // since single page apps may get wrong data for client-side navigation, we only track the web vitals
    // until the second page view. This means we must count them here.
    pageViewCounter += 1;
    if (pageViewCounter < 2) {
        onCLS(metric => trackCLS(trackingService, metric));
        onFID(metric => trackFID(trackingService, metric));
        onLCP(metric => trackLCP(trackingService, metric));
        onINP(metric => trackINP(trackingService, metric));
    }
};

export const WebVitalsInternal = {
    resetPageViewCounter: () => { pageViewCounter = 0; },
};

const WebVitals = {
    trackWebVitals,
};

export default WebVitals;
