
function DeferredExecution(window, latency) {
    let executionTimeout = null;

    this.execute = (callback) => {
        if (executionTimeout) {
            window.clearTimeout(executionTimeout);
        }
        executionTimeout = window.setTimeout(callback, latency);
    };
}

export default DeferredExecution;
