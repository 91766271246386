
function AjvBasedValidator(ajv) {
    this.validate = (schema, data, errorCallback) => {
        if (!ajv.validate(schema, data)) {
            errorCallback(`${ajv.errorsText()} (${JSON.stringify(data)})`);
        }
    };
}

export default AjvBasedValidator;
