import impression from './schema/impression.json';
import inspirationCampaign from './schema/inspiration/campaign.json';
import weeklyRecipesCampaign from './schema/weekly-recipes/campaign.json';
import magazineArticle from './schema/magazine/article.json';
import magazineCategory from './schema/magazine/category.json';
import magazineCampaign from './schema/magazine/campaign.json';
import magazineReference from './schema/magazine/reference.json';
import recipeList from './schema/recipe/list.json';
import recipeCollection from './schema/recipe/collection.json';
import preferencesTeaser from './schema/preferences-teaser.json';
import lastSearches from './schema/last-searches.json';
import recipeCampaign from './schema/recipe/campaign.json';
import url from './schema/url.json';
import trackingId from './schema/tracking-id.json';
import recipe from './schema/recipe.json';
import recipeImage from './schema/recipe-image.json';
import result from './schema/result.json';
import ingredient from './schema/ingredient.json';
import food from './schema/food.json';
import teaser from './schema/teaser.json';
import subscribe from './schema/subscribe.json';
import newsletterSubscription from './schema/newsletter-subscription.json';
import product from './schema/product.json';
import paymentMethod from './schema/paymentMethod.json';
import subscribeEvent from './schema/event/subscribe.json';
import video from './schema/video.json';
import videoFormat from './schema/video-format.json';
import event from './schema/event/event.json';
import save from './schema/event/save.json';
import rate from './schema/event/rate.json';
import create from './schema/event/create.json';
import searchInit from './schema/event/searchInit.json';
import searchSubmit from './schema/event/searchSubmit.json';
import videoEvent from './schema/event/video.json';
import teaserEvent from './schema/event/teaser.json';
import toggleEvent from './schema/event/toggle.json';
import sortEvent from './schema/event/sort.json';
import pagingEvent from './schema/event/paging.json';
import navigationEvent from './schema/event/navigation.json';
import genericEvent from './schema/event/generic.json';
import actionMenuEvent from './schema/event/actionMenu.json';
import cookbookActionEvent from './schema/event/cookbookAction.json';
import changeViewEvent from './schema/event/changeView.json';
import deleteEvent from './schema/event/delete.json';
import undoEvent from './schema/event/undo.json';
import cookbook from './schema/cookbook/cookbook.json';
import cookbookCollection from './schema/cookbook/collection.json';
import sliderEvent from './schema/event/slider.json';
import slider from './schema/slider.json';
import slide from './schema/slide.json';
import inspirationImpression from './schema/inspiration/impression.json';
import sourcePointEvent from './schema/event/sourcepoint.json';
import purchaseEvent from './schema/event/purchase.json';
import smartlistLandingPage from './schema/smartlist/landingpage.json';
import recipeLandingPage from './schema/recipe/landingpage.json';
import homepage from './schema/homepage.json';
import nutrition from './schema/nutrition.json';
import registerEvent from './schema/event/register.json';
import cookbookV2Recipe from './schema/cookbookv2/recipe.json';
import cookbookV2Collection from './schema/cookbookv2/collection.json';
import searchEntryPage from './schema/search/entrypage.json';
import gotoEvent from './schema/event/goto.json';
import loadMoreEvent from './schema/event/loadMore.json';
import webVitalsEvent from './schema/event/webVitals.json';
import subscriptionProfile from './schema/subscription-profile.json';
import loginInitEvent from './schema/event/loginInit.json';
import loginCtaClosedEvent from './schema/event/loginCtaClosed.json';
import choiceFeedbackEvent from './schema/event/choiceFeedback.json';
import checkoutViewStep from './schema/event/checkoutViewStep.json';
import paymentSelect from './schema/event/paymentSelect.json';
import checkoutPaymentDone from './schema/event/checkoutPaymentDone.json';
import checkoutFinished from './schema/event/checkoutFinished.json';
import manageSubscription from './schema/event/manageSubscription.json';
import termsOfUse from './schema/event/termsOfUse.json';
import updatePaymentMethod from './schema/event/updatePaymentMethod.json';


function SchemaConfigurator() {
    this.configureSchema = (validator) => {
        validator.addSchema(impression, 'impression');
        validator.addSchema(magazineArticle, 'magazine-article');
        validator.addSchema(magazineCategory, 'magazine-category');
        validator.addSchema(magazineCampaign, 'magazine-campaign');
        validator.addSchema(magazineReference, 'magazine-reference');
        validator.addSchema(recipeList, 'recipe-list');
        validator.addSchema(recipeCollection, 'recipe-collection');
        validator.addSchema(preferencesTeaser, 'preferences-teaser');
        validator.addSchema(lastSearches, 'last-searches');
        validator.addSchema(recipeCampaign, 'recipe-campaign');
        validator.addSchema(recipe, 'recipe');
        validator.addSchema(recipeImage, 'recipe-image');
        validator.addSchema(result, 'result');
        validator.addSchema(ingredient, 'ingredient');
        validator.addSchema(inspirationCampaign, 'inspiration-campaign');
        validator.addSchema(weeklyRecipesCampaign, 'weekly-recipes-campaign');
        validator.addSchema(food, 'food');
        validator.addSchema(teaser, 'teaser');
        validator.addSchema(video, 'video');
        validator.addSchema(videoFormat, 'video-format');
        validator.addSchema(subscribe, 'subscribe');
        validator.addSchema(newsletterSubscription, 'newsletter-subscription');
        validator.addSchema(product, 'product');
        validator.addSchema(paymentMethod, 'paymentMethod');
        validator.addSchema(event, 'event');
        validator.addSchema(save, 'save-event');
        validator.addSchema(rate, 'rate-event');
        validator.addSchema(create, 'create-event');
        validator.addSchema(searchInit, 'searchInit-event');
        validator.addSchema(searchSubmit, 'searchSubmit-event');
        validator.addSchema(videoEvent, 'video-event');
        validator.addSchema(teaserEvent, 'teaser-event');
        validator.addSchema(toggleEvent, 'toggle-event');
        validator.addSchema(subscribeEvent, 'subscribe-event');
        validator.addSchema(url, 'url');
        validator.addSchema(trackingId, 'tracking-id');
        validator.addSchema(sortEvent, 'sort-event');
        validator.addSchema(pagingEvent, 'paging-event');
        validator.addSchema(navigationEvent, 'navigation-event');
        validator.addSchema(genericEvent, 'generic-event');
        validator.addSchema(actionMenuEvent, 'actionMenu-event');
        validator.addSchema(cookbookActionEvent, 'cookbookAction-event');
        validator.addSchema(changeViewEvent, 'changeView-event');
        validator.addSchema(cookbook, 'cookbook');
        validator.addSchema(cookbookCollection, 'cookbook-collection');
        validator.addSchema(sliderEvent, 'slider-event');
        validator.addSchema(slider, 'slider');
        validator.addSchema(slide, 'slide');
        validator.addSchema(inspirationImpression, 'inspiration-impression');
        validator.addSchema(sourcePointEvent, 'sourcepoint-event');
        validator.addSchema(purchaseEvent, 'purchase-event');
        validator.addSchema(smartlistLandingPage, 'smartlist-landingpage');
        validator.addSchema(recipeLandingPage, 'recipe-landingpage');
        validator.addSchema(homepage, 'homepage');
        validator.addSchema(nutrition, 'nutrition');
        validator.addSchema(registerEvent, 'register-event');
        validator.addSchema(deleteEvent, 'delete-event');
        validator.addSchema(undoEvent, 'undo-event');
        validator.addSchema(cookbookV2Recipe, 'cookbook-v2-recipe');
        validator.addSchema(cookbookV2Collection, 'cookbook-v2-collection');
        validator.addSchema(searchEntryPage, 'search-entrypage');
        validator.addSchema(gotoEvent, 'goto-event');
        validator.addSchema(loadMoreEvent, 'loadMore-event');
        validator.addSchema(webVitalsEvent, 'webVitals-event');
        validator.addSchema(subscriptionProfile, 'subscription-profile');
        validator.addSchema(loginInitEvent, 'loginInit-event');
        validator.addSchema(loginCtaClosedEvent, 'loginCtaClosed-event');
        validator.addSchema(choiceFeedbackEvent, 'choiceFeedback-event');
        validator.addSchema(checkoutViewStep, 'checkoutViewStep-event');
        validator.addSchema(paymentSelect, 'paymentSelect-event');
        validator.addSchema(checkoutPaymentDone, 'checkoutPaymentDone-event');
        validator.addSchema(checkoutFinished, 'checkoutFinished-event');
        validator.addSchema(manageSubscription, 'manageSubscription-event');
        validator.addSchema(termsOfUse, 'termsOfUse-event');
        validator.addSchema(updatePaymentMethod, 'updatePaymentMethod-event');
    };
}

export default SchemaConfigurator;
