function PositionExceptionTeaserClickTrackingDecision() {
    const exceptionList = [
        /^(all-recipes-cookbook)/,
        /^(appstores_smartlist_landingpage)/,
        /^(collection-editVisibility-private-cookbook)/,
        /^(collection-editVisibility-visible-cookbook)/,
        /^(cookbook)/,
        /^(delivery-service)/,
        /^(featured_user_hubpage)/,
        /^(forum_hubpage)/,
        /^(handpicked_recipes_hubpage)/,
        /^(homepage_|markenrezeptbox_homepage$)/,
        /^(inspiration)/,
        /^(last_searches_suse)/,
        /^(magazine_themenwelt)/,
        /^(markenrezeptbox_suse)/,
        /^(markenrezeptbox_recipe_landingpage)/,
        /^(my-collections-cookbook)/,
        /^(my-saved-recipes-cookbook)/,
        /^(navigation-footer)/,
        /^(newsletter-sign-up-rdt)/,
        /^(newsletter-sign-up-sitewide)/,
        /^(partner_integrations_hubpage)/,
        /^(plus)/,
        /^(popular_categories_suse)/,
        /^(popular_searches_suse)/,
        /^(privateRecipe-create-cookbook)/,
        /^(quick_recipes_hubpage)/,
        /^(recipe-collection-cookbook)/,
        /^(recipe-note-cookbook)/,
        /^(recipe_collections_hubpage)/,
        /^(recipe_recommendation_suse)/,
        /^(recipe_sponsored_video)/,
        /^(search-cookbook)/,
        /^(search-recipecollection)/,
        /^(search_filters_hubpage)/,
        /^(search_results_hubpage)/,
        /^(seasonal_recipes_hubpage)/,
        /^(smartlist_rds)/,
        /^(smartlist_shopping_list)/,
        /^(tipps_hubpage)/,
        /^(videos_hubpage)/,
        /^(promo_card)/,
        /^(promo_card)/,
    ];

    this.shouldTrackTeaserClick = (event) => {
        const position = event.target.position;
        // If the position matches the exception, return true and exit
        return exceptionList.some(regex => position.match(regex) !== null);
    };
}

export default PositionExceptionTeaserClickTrackingDecision;
