function CampaignContentResolver() {
    this.resolve = (content) => {
        let campaign = null;

        if (content.campaign) {
            campaign = content.campaign;
        } else if (content.type === 'magazine-campaign') {
            campaign = content;
        }

        return campaign;
    };
}

export default CampaignContentResolver;
