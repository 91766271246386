
function AjvValidatorLoader(scriptLoader, window, schemaConfigurator, validatorFactory) {
    this.load = (callback) => {
        scriptLoader.load('/tracking/ajv.min.js', () => {
            const ajv = new window.Ajv({ verbose: true });
            schemaConfigurator.configureSchema(ajv);

            const validator = validatorFactory(ajv);

            callback(validator);
        });
    };
}

export default AjvValidatorLoader;
