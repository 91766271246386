function DocumentReadyNotifier(document) {
    const listeners = [];
    let initialized = false;

    const notifyListeners = () => listeners.forEach(listener => listener.onDocumentReady(document));

    this.addListener = (listener) => {
        if (initialized) {
            throw new Error('Listeners can only be registrered on the DocumentReadyNotifier, when it has not already been initialized.');
        }
        listeners.push(listener);
    };

    this.init = () => {
        initialized = true;
        if (document.readyState !== 'loading') {
            notifyListeners();
        } else {
            document.addEventListener('DOMContentLoaded', notifyListeners);
        }
    };
}

export default DocumentReadyNotifier;
