import container from 'chefkoch-containerjs';
import CompositeMapper from './mappers/compositeMapper';
import CompositeEventMapper from './mappers/compositeEventMapper';
import TrackingService from './trackingService';
import DeferredExecution from '../deferredExecution';
import TeaserMapper from './mappers/teaserMapper';
import DataLayerService from './dataLayerService';
import ContentMapper from './mappers/contentMapper';
import PayedContentTypeMapper from './mappers/payedContentTypeMapper';
import UserMapper from './mappers/userMapper';
import TeaserClickEventMapper from './mappers/teaserClickEventMapper';
import SliderEventMapper from './mappers/sliderEventMapper';

container.service('dataLayerService', DataLayerService, 'window');

container.factory('gtmImpressionMapper', (c) => {
    const compositeMapper = new CompositeMapper(c.get('logger'));

    compositeMapper.addMapper(c.get('gtmContentMapper'));
    compositeMapper.addMapper(c.get('gtmPayedContentTypeMapper'));
    // compositeMapper.addMapper(c.get('gtmResultMapper'));
    // compositeMapper.addMapper(c.get('gtmResponsiveViewMapper'));
    compositeMapper.addMapper(c.get('gtmUserMapper'));
    // compositeMapper.addMapper(c.get('gtmVersionMapper'));

    return compositeMapper;
});

container.factory('gtmEventMapper', (c) => {
    const compositeEventMapper = new CompositeEventMapper(c.get('logger'));
    compositeEventMapper.addMapper(c.get('gtmTeaserClickEventMapper'));
    compositeEventMapper.addMapper(c.get('gtmSliderEventMapper'));
    return compositeEventMapper;
});

// container.service('gtmResponsiveViewMapper', ResponsiveViewMapper, 'logger');
container.service('gtmContentMapper', ContentMapper, 'logger');
container.service('gtmPayedContentTypeMapper', PayedContentTypeMapper, 'logger');
// container.service('gtmResultMapper', ResultMapper, 'logger');
container.service('gtmUserMapper', UserMapper, 'logger');
// container.service('gtmVersionMapper', VersionMapper, 'logger');
container.service('gtmTeaserMapper', TeaserMapper, 'campaignContentResolver', 'logger');
container.service('gtmTeaserClickEventMapper', TeaserClickEventMapper, 'campaignContentResolver', 'compositeTeaserClickTrackingDecision', 'logger');
container.service('gtmSliderEventMapper', SliderEventMapper, 'logger');


container.constant('gtmLatentPixelExecutionLatency', 1000);
container.service('gtmLatentPixelExecution', DeferredExecution, 'window', 'gtmLatentPixelExecutionLatency');

container.service('gtmTrackingService', TrackingService, 'gtmLatentPixelExecution', 'gtmImpressionMapper', 'dataLayerService', 'logger', 'gtmTeaserMapper', 'gtmEventMapper');
