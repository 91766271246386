function DocumentParser(impressionCollector, trackingService, teaserTrackingContext, webVitals, userDataCollector) {
    /**
     * Adds click listener to teaser
     *
     * @param element
     * @param data
     */
    const addClickListenerOnChildLinks = (element, data) => {
        // but always add the event listener
        const links = Array.prototype.slice.call(element.getElementsByTagName('a'));
        if (links && links.length > 0) {
            links.forEach((link) => {
                link.addEventListener(
                    'click',
                    () => trackingService.trackEvent({
                        type: 'event',
                        name: 'click',
                        target: data,
                    }),
                );
            });
        }
    };

    /**
     * Adds click listener to links with functions
     *
     * @param document
     * @param element
     */
    const addClickListenerOnGenericLinks = (document, element) => {
        const closestElement = element.closest('[data-tracking]') || undefined;
        const bodyData = document.body.getAttribute('data-tracking') || undefined;
        let childData;

        if (typeof closestElement === 'undefined' || typeof bodyData === 'undefined') {
            return false;
        }

        if (closestElement !== document.body) {
            childData = closestElement.getAttribute('data-tracking');
        }

        element.addEventListener(
            'click',
            () => trackingService.trackEvent({
                type: 'event',
                name: 'generic',
                category: element.getAttribute('data-tracking-name'),
                action: element.getAttribute('data-tracking-step'),
                value: element.getAttribute('data-tracking-value'),
                parentData: bodyData,
                childData,
            }),
        );

        return true;
    };

    /**
     * Computes the offset of an element
     *
     * @param element
     * @param data
     * @param positionOffsets
     */
    const setPositioningContext = (element, data, positionOffsets) => {
        // Get & set a positioning context from a closest parent if possible
        const parent = element.closest('[data-tracking-position]');
        if (parent) {
            data.position = parent.getAttribute('data-tracking-position');
        }

        const position = data.position || 'missing';
        if (!positionOffsets[position]) {
            positionOffsets[position] = 0;
        }
        positionOffsets[position] += 1;
        data.offset = positionOffsets[position];
    };

    /**
     * Read data-tracking attribute of teaser, compute positioningContext, track teaser regarding to its tracking strategy
     * and adds always a click listener.
     *
     * @param element
     * @param positionOffsets
     */
    const handleTeaser = (element, positionOffsets) => {
        // Extract teaser model from DOM
        const json = element.getAttribute('data-tracking');
        const data = json ? JSON.parse(json) : null;

        if (data) {
            setPositioningContext(element, data, positionOffsets);
            teaserTrackingContext.selectStrategyByName(data.strategy);
            teaserTrackingContext.track(data, element, trackingService);
            addClickListenerOnChildLinks(element, data);
        }
    };

    /**
     * Handle all Generic Links
     * @param document
     */
    const handleGenericLinks = (document) => {
        const elements = Array.prototype.slice.call(document.querySelectorAll('body [data-tracking-name]'));

        if (elements && elements.length > 0) {
            elements.forEach((element) => {
                try {
                    addClickListenerOnGenericLinks(document, element);
                } catch (ex) {
                    // Ignore invalid json data-tracking element
                    // Go to the next data-tracking element
                }
            });
        }
    };

    /**
     * Gets all elements with data-tracking attribute (aka teasers), loops over them and handle them
     * @param document
     */
    const handleTeasers = (document) => {
        const elements = Array.prototype.slice.call(document.querySelectorAll('body [data-tracking]'));
        if (elements && elements.length > 0) {
            const positionOffsets = [];

            elements.forEach((element) => {
                try {
                    handleTeaser(element, positionOffsets);
                } catch (ex) {
                    // Ignore invalid json data-tracking element
                    // Go to the next data-tracking element
                }
            });
        }
    };

    this.onDocumentReady = (document) => {
        const baseImpression = {};
        const userImpressionPromise = userDataCollector.collect();
        impressionCollector.collect(document, baseImpression);

        const defaultUserImpression = {
            authenticated: false,
            hashedUserId: null,
        };
        return userImpressionPromise.then((returnedUserImpression) => {
            this.continueTracking(document, baseImpression, returnedUserImpression);
        }).catch(() => {
            // could not request user data => no user data will be set
            this.continueTracking(document, baseImpression, defaultUserImpression);
        });
    };

    this.continueTracking = (document, baseImpression, userImpression) => {
        Object.assign(baseImpression, userImpression);

        trackingService.trackImpression(baseImpression);
        webVitals.trackWebVitals(trackingService);

        handleTeasers(document);
        handleGenericLinks(document);

        trackingService.initialTrackingIsFinished();
    };
}

export default DocumentParser;
