function DataLayerService(window) {
    const dataLayer = window.dataLayer || [];
    const supportedEcommerceEventNames = [
        'view_item_list',
        'select_item',
    ];

    this.isSupportedEcommerceEvent = name => supportedEcommerceEventNames.includes(name);

    // global data must be given in eventData
    this.pageview = (eventData) => {
        dataLayer.push({
            event: 'page_view',
            data: eventData,
        });
    };

    this.trackEvent = (eventData) => {
        if (eventData.type === 'event' && (eventData.name || eventData.eventName)) {
            const eventName = eventData.name || eventData.eventName;
            delete eventData.type;
            delete eventData.name;
            delete eventData.eventName;
            dataLayer.push({
                event: eventName,
                data: eventData,
            });
        }
    };

    this.trackEcommerceEvent = (eventName, items) => {
        if (this.isSupportedEcommerceEvent(eventName) && items && items.length > 0) {
            dataLayer.push({
                event: eventName,
                ecommerce: {
                    items,
                },
            });
        }
    };

    window.dataLayer = dataLayer;
}

export default DataLayerService;
