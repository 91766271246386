function SliderEventMapper(injectedLogger) {
    const logger = injectedLogger || { debug() {} };

    this.map = (event) => {
        const sliderEvent = {};
        if (event.name === 'slider') {
            const action = event.action || '';
            if (action !== '' && event.ref && event.ref.context) {
                sliderEvent.type = 'event';
                sliderEvent.eventName = 'slider_interaction';
                sliderEvent.content_id = `${event.ref.context.type}-${event.ref.context.id}`;
                sliderEvent.event_interaction_type = action;

                if (event.ref.context.campaign) {
                    sliderEvent.campaign_id = `${event.ref.context.campaign.type}-${event.ref.context.campaign.id}`;
                }

                logger.debug(`[gtm] Mapping slider_interaction event with action ${action} to data layer`);
            }
        }
        return sliderEvent;
    };
}

export default SliderEventMapper;
