/**
 * map and send events to GoogleAnalytics
 */
function CompositeEventMapper(injectedLogger) {
    const logger = injectedLogger || { debug() {} };
    const mappers = [];
    let eventData = [];

    this.addMapper = (mapper) => {
        logger.debug('[gtm] Add new event mapper');
        mappers.push(mapper);
    };

    this.map = (event, dataLayerService) => {
        logger.debug('[gtm] Start event mapping');
        mappers.forEach((mapper) => {
            eventData.push(mapper.map(event));
        });
        const item = Object.assign({}, ...eventData);
        eventData = [];
        const eventName = item.eventName || item.name || '';
        if (dataLayerService.isSupportedEcommerceEvent(eventName)) {
            delete item.eventName;
            dataLayerService.trackEcommerceEvent(eventName, [item]);
        } else {
            dataLayerService.trackEvent(item);
        }
    };
}

export default CompositeEventMapper;
