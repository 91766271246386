function LazyLoadedValidator(validatorLoader) {
    let validator = null;
    let loaderTriggered = false;

    const queue = [];

    this.validate = (schema, data, errorCallback) => {
        if (validator) {
            validator.validate(schema, data, errorCallback);
        } else {
            queue.push([schema, data, errorCallback]);
            if (!loaderTriggered) {
                loaderTriggered = true;
                validatorLoader.load((loadedValidator) => {
                    validator = loadedValidator;
                    while (queue.length > 0) {
                        const item = queue.shift();
                        validator.validate(...item);
                    }
                });
            }
        }
    };
}

export default LazyLoadedValidator;
