function TeaserTrackVisibleElementsStrategy() {
    this.getName = () => 'trackVisibleElements';

    // Only track when element is visible - https://stackoverflow.com/questions/14122013/detect-if-an-element-is-visible-without-using-jquery
    this.track = (data, element, trackingService) => {
        if (element.offsetWidth > 0 || element.offsetHeight > 0) {
            trackingService.trackTeaser(data);
        }
    };
}
export default TeaserTrackVisibleElementsStrategy;
